import request from "@/utils/request"

export default {
	// 获取列表
	apiGetPage: data =>
		request(`/project/${data.projectId}/drug-type`, {
			params: data
		}),
	// 新增
	apiAdd: data =>
		request(`/project/${data.projectId}/drug-type`, {
			method: "post",
			body: data
		}),
	// 获取详情
	apiGet: data =>
		request(`/project/${data.projectId}/drug-type/${data.id}`, {
			params: data
		}),
	// 修改
	apiUpdate: data =>
		request(`/project/${data.projectId}/drug-type/${data.id}`, {
			method: "put",
			body: data
		}),
	// 删除药物
	apiDelete: data =>
		request(`/project/${data.projectId}/drug-type/${data.id}`, {
			method: "post",
			body: data
		}),
	// 新增药品耗材
	apiAddSupply: data =>
		request(`/project/${data.projectId}/drug-supply-type`, {
			method: "post",
			body: data
		}),
	// 修改药品耗材
	apiUpdateSupply: data =>
		request(`/project/${data.projectId}/drug-supply-type/${data.id}`, {
			method: "put",
			body: data
		}),
	// 查看药品耗材
	apiGetSupply: data =>
		request(`/project/${data.projectId}/drug-supply-type/${data.id}`, {
			method: "get"
		}),
	// 删除药品耗材
	apiDeleteSupply: data =>
		request(`/project/${data.projectId}/drug-supply-type/${data.id}`, {
			method: "delete"
		}),
	// 获取仓库列表
	apiGetPharmacy: data =>
		request("/pharmacy", {
			method: "get",
			params: data
		})
}

<template>
	<Modal
		width="700"
		:title="title"
		:value="visible"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>

		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="耗材名称：" prop="supplyName">
						<template v-if="isReadonly">{{
							formData.supplyName || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.supplyName"
								placeholder="请输入耗材名称"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="耗材英文名称：" prop="supplyEnglishName">
						<template v-if="isReadonly">{{
							formData.supplyEnglishName || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.supplyEnglishName"
								placeholder="请输入耗材英文名称"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="使用方式：" prop="useDescription">
						<template v-if="isReadonly">{{
							formData.useDescription || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.useDescription"
								placeholder="请输入使用方式"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="耗材类别：" prop="supplyCategory">
						<template v-if="isReadonly">{{
							formData.supplyCategory || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.supplyCategory"
								placeholder="请输入耗材类别"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="包装：" prop="packages">
						<template v-if="isReadonly">{{
							formData.packages || "--"
						}}</template>
						<template v-else>
							<Input v-model="formData.packages" placeholder="请输入包装" />
						</template>
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="注意事项：" prop="attention">
						<template v-if="isReadonly">{{
							formData.attention || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.attention"
								placeholder="请输入注意事项"
							/>
						</template>
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="制造厂商：" prop="organizationName">
						<template v-if="isReadonly">{{
							formData.organizationName || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.organizationName"
								placeholder="请输入制造厂商"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="质量：" prop="mass">
						<template v-if="isReadonly">{{ formData.mass || "--" }}</template>
						<template v-else>
							<Input v-model="formData.mass" placeholder="请输入质量" />
						</template>
					</FormItem>
				</Col>
				<!--        <Col span="12">
          <FormItem label="适用症：" prop="name">
            <template v-if="isReadonly">{{formData.name}}</template>
            <template v-else>
              <Input v-model="formData.name" placeholder="请输入适用症"/>
            </template>
          </FormItem>
        </Col>-->
				<Col span="12">
					<FormItem label="规格：" prop="specifications">
						<template v-if="isReadonly">{{
							formData.specifications || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.specifications"
								placeholder="请输入规格"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="材质：" prop="material">
						<template v-if="isReadonly">{{
							formData.material || "--"
						}}</template>
						<template v-else>
							<Input v-model="formData.material" placeholder="请输入规格" />
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="存储条件：" prop="name">
						<template v-if="isReadonly">{{ formData.name || "--" }}</template>
						<template v-else>
							<Input v-model="formData.name" placeholder="请输入存储条件" />
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="仓库名称：" prop="pharmacyId">
						<template v-if="isReadonly">{{
							formData.pharmacyName || "--"
						}}</template>
						<template v-else>
							<Select
								v-model="formData.pharmacyId"
								filterable
								clearable
								placeholder="请选择仓库名称"
							>
								<Option
									v-for="list in pharmacyList"
									:value="list.id"
									:key="list.id"
									:label="list.pharmacyName"
								></Option>
							</Select>
						</template>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="备注：" prop="remark">
						<template v-if="isReadonly">{{ formData.remark || "--" }}</template>
						<template v-else>
							<Input
								v-model="formData.remark"
								placeholder="请输入备注"
								type="textarea"
							/>
						</template>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/drug"

const { apiGetSupply, apiAddSupply, apiUpdateSupply } = api

const TYPE_MAP = {
	add: { title: "新增耗材", key: "add" },
	read: { title: "查看耗材", key: "read" },
	update: { title: "修改耗材", key: "update" }
}

export default {
	name: "MaterialARU", // 耗材 add read(view) update
	props: ["type", "visible", "id", "pharmacyList"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				supplyName: {
					required: true,
					message: "耗材名称不能为空",
					trigger: "blur"
				},
				useDescription: {
					required: true,
					message: "使用方式不能为空",
					trigger: "blur"
				},
				pharmacyId: {
					required: true,
					message: "仓库名称不能为空",
					trigger: "blur change"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.title = (TYPE_MAP[this.type] || {}).title || "药品"
				if (this.id) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGetSupply({
							projectId: this.$route.params.projectId,
							id: this.id,
							type: 1
						})
						if (res) {
							this.formData = {
								...this.formData,
								...res.data
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	computed: {
		// 查看(read)是不需要编辑的的
		isReadonly() {
			return (this.type || "") === TYPE_MAP.read.key
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.id) {
							res = await apiUpdateSupply({
								projectId: this.$route.params.projectId,
								id: this.id,
								...this.formData
							})
						} else {
							res = await apiAddSupply({
								projectId: this.$route.params.projectId,
								id: this.id,
								...this.formData
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	},
	TYPE_MAP
}
</script>

<style scoped></style>

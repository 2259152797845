<template>
	<Modal
		width="700"
		:title="title"
		:value="visible"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="药物名称：" prop="drugName">
						<template v-if="isReadonly">{{
							formData.drugName || "--"
						}}</template>
						<template v-else>
							<Input v-model="formData.drugName" placeholder="请输入药物名称" />
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="药准字号：" prop="permitCode">
						<template v-if="isReadonly">{{
							formData.permitCode || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.permitCode"
								placeholder="请输入药准字号"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="药物英文：" prop="drugEnglishName">
						<template v-if="isReadonly">{{
							formData.drugEnglishName || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.drugEnglishName"
								placeholder="请输入药物英文"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="通用名：" prop="universalName">
						<template v-if="isReadonly">{{
							formData.universalName || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.universalName"
								placeholder="请输入通用名"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="给药途径：" prop="drugRoute">
						<template v-if="isReadonly">{{
							formData.drugRouteName || "--"
						}}</template>
						<template v-else>
							<Select
								v-model="formData.drugRoute"
								filterable
								clearable
								placeholder="请选择给药途径"
							>
								<Option
									v-for="list in drugRouteList"
									:value="list.id"
									:key="list.id"
									:label="list.name"
								></Option>
							</Select>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="临床用药分类：" prop="drugType">
						<template v-if="isReadonly">{{
							formData.drugTypeName || "--"
						}}</template>
						<template v-else>
							<Select
								v-model="formData.drugType"
								filterable
								clearable
								placeholder="请选择临床用药分类"
							>
								<Option
									v-for="list in drugTypeList"
									:value="list.id"
									:key="list.id"
									:label="list.name"
								></Option>
							</Select>
						</template>
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="单位：" prop="unit">
						<template v-if="isReadonly">{{
							formData.unitName || "--"
						}}</template>
						<template v-else>
							<Select
								v-model="formData.unit"
								:label-in-value="true"
								filterable
								clearable
								placeholder="请选择单位"
							>
								<Option
									v-for="list in unitList"
									:value="list.id"
									:key="list.id"
									:label="list.name"
								></Option>
							</Select>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="规格：" prop="specifications">
						<template v-if="isReadonly">{{
							formData.specifications || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.specifications"
								placeholder="请输入药物数量"
							/>
						</template>
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="剂型：" prop="dosageForm">
						<template v-if="isReadonly">{{
							formData.dosageForm || "--"
						}}</template>
						<template v-else>
							<Input v-model="formData.dosageForm" placeholder="请输入剂型" />
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="是否处方药：" prop="prescription">
						<template v-if="isReadonly">{{
							formData.prescription ? "是" : "否"
						}}</template>
						<template v-else>
							<Select
								v-model="formData.prescription"
								:label-in-value="true"
								placeholder="请选择是否处方药"
							>
								<Option :value="1">是</Option>
								<Option :value="0">否</Option>
							</Select>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="制造厂商：" prop="organizationName">
						<template v-if="isReadonly">{{
							formData.organizationName || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.organizationName"
								placeholder="请输入制造厂商"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="仓库名称：" prop="pharmacyId">
						<template v-if="isReadonly">{{
							formData.pharmacyName || "--"
						}}</template>
						<template v-else>
							<Select
								v-model="formData.pharmacyId"
								filterable
								clearable
								placeholder="请选择仓库名称"
							>
								<Option
									v-for="list in pharmacyList"
									:value="list.id"
									:key="list.id"
									:label="list.pharmacyName"
								></Option>
							</Select>
						</template>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="适用症：" prop="indication">
						<template v-if="isReadonly">{{
							formData.indication || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.indication"
								type="textarea"
								placeholder="请输入适用症"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="用法用量：" prop="usageDescr">
						<template v-if="isReadonly">{{
							formData.usageDescr || "--"
						}}</template>
						<template v-else>
							<Input
								v-model="formData.usageDescr"
								type="textarea"
								placeholder="请输入用法用量"
							/>
						</template>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="备注：" prop="remark">
						<template v-if="isReadonly">{{ formData.remark || "--" }}</template>
						<template v-else>
							<Input
								v-model="formData.remark"
								type="textarea"
								placeholder="请输入备注"
							/>
						</template>
					</FormItem>
				</Col>
				<!--<Col span="12">
          <FormItem label="注册分类：" prop="name">
            <Input v-model="formData.name" placeholder="请输入注册分类"/>
          </FormItem>
        </Col>-->
				<!--<Col span="12">
          <FormItem label="药物数量：" prop="totalAmount">
            <Input v-model="formData.totalAmount" placeholder="请输入药物数量"/>
          </FormItem>
        </Col>-->
				<!--<Col span="12">
          <FormItem label="成分：" prop="name">
            <Input v-model="formData.name" placeholder="请输入药物数量"/>
          </FormItem>
        </Col>-->

				<!--<Col span="12">
          <FormItem label="最小包装剂量：" prop="name">
            <Input v-model="formData.name" placeholder="请输入最小包装剂量"/>
          </FormItem>
        </Col>-->

				<!--<Col span="12">
          <FormItem label="用药方式：" prop="name">
            <Input v-model="formData.name" placeholder="请输入用药方式"/>
          </FormItem>
        </Col>-->
				<!--<Col span="12">
          <FormItem label="药品包装形式：" prop="name">
            <Input v-model="formData.name" placeholder="请输入药品包装形式"/>
          </FormItem>
        </Col>-->
				<!--<Col span="12">
          <FormItem label="注意事项：" prop="name">
            <Input v-model="formData.name" placeholder="请输入注意事项"/>
          </FormItem>
        </Col>-->
				<!--<Col span="12">
          <FormItem label="使用说明：" prop="name">
            <Input v-model="formData.name" placeholder="请输入使用说明"/>
          </FormItem>
        </Col>-->
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/drug"

const { apiAdd, apiUpdate, apiGet } = api

const TYPE_MAP = {
	add: { title: "新增药物", key: "add" },
	read: { title: "查看药物", key: "read" },
	update: { title: "修改药物", key: "update" }
}

export default {
	name: "MedicineARU", // medicine add read(view) update
	props: [
		"type",
		"visible",
		"id",
		"sponsorList",
		"drugRouteList",
		"drugTypeList",
		"pharmacyList",
		"unitList"
	],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				drugName: {
					required: true,
					message: "药物名称不能为空",
					trigger: "blur"
				},
				drugType: {
					required: true,
					message: "临床分类药物不能为空",
					trigger: "blur change"
				},
				drugRoute: {
					required: true,
					message: "给药途径不能为空",
					trigger: "blur change"
				},
				pharmacyId: {
					required: true,
					message: "仓库名称不能为空",
					trigger: "blur change"
				},
				organizationName: {
					required: true,
					message: "制造厂商不能为空",
					trigger: "blur"
				},
				totalAmount: [
					{ required: true, message: "药物数量不能为空", trigger: "blur" },
					{
						...this.rules.positiveInteger,
						message: "药物数量必须为正整数",
						trigger: "blur"
					}
				]
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.title = (TYPE_MAP[this.type] || {}).title || "药品"
				if (this.id) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({
							projectId: this.$route.params.projectId,
							id: this.id
						})
						if (res) {
							this.formData = {
								...this.formData,
								...res.data
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	computed: {
		// 查看(read)药品是不需要编辑
		isReadonly() {
			return (this.type || "") === TYPE_MAP.read.key
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.id) {
							res = await apiUpdate({
								projectId: this.$route.params.projectId,
								...this.formData,
								id: this.id
							})
						} else {
							res = await apiAdd({
								projectId: this.$route.params.projectId,
								...this.formData
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	},
	TYPE_MAP
}
</script>

<style scoped></style>
